<template>
  <div class="registration-step-1-block">
    <div class="login-block-container">
      <div class="reg-step-form">
        <h3>{{ $lang.app.reset_account }}</h3>
        <p>{{ $lang.app.verify_identity }}</p>
        <p>{{ $lang.app.your_email }} {{ $route.query.email }}</p>
        <form @submit.prevent="submit">
          <div class="login-control wrap-buttons">
            <button class="transparent-button" @click="$router.push('/auth/login-parent')">{{ $lang.app.cancel }}</button>
            <button class="blue-button large-button" type="submit">{{ $lang.app.send_email }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
export default {
  name: 'SendToEmail',
  directives: { maska },
  methods: {
    submit() {
      this.$axios.get('/auth/restore-password/', {
        params: {
          surname: this.$route.query.surname,
          email: this.$route.query.email,
        }
      }).then(() => {
        this.$router.push(`/auth/email-success?email=${this.$route.query.email}`)
      })
    }
  }
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/auth.css';
</style>